<!-- 我的订单头部分类和搜索 -->
<template>
  <div class="discountclass">
    <div class="menu">
      <button :disabled='orderDs' :class='{choice:choices==1}' @click='choice(1)'>未使用<i v-if='waits'>（{{waits}}）</i></button>
      <button :disabled='orderDs' :class='{choice:choices==2}' @click='choice(2)'>已使用<i v-if='waits1'>（{{waits1}}）</i></button>
      <button :disabled='orderDs' :class='{choice:choices==3}' @click='choice(3)'>已过期<i v-if='waits2'>（{{waits2}}）</i></button>
<!--       <button :disabled='orderDs' :class='{choice:choices==3}' @click='choice(3)'>待评价<i v-if='waits2'>{{waits2}}</i></button>
      <button :disabled='orderDs' :class='{choice:choices==4}' @click='choice(4)'>已完成<i v-show='false'>1</i></button>
      <button :disabled='orderDs' :class='{choice:choices==5}' @click='choice(5)'>退/换货<i v-show='false'>1</i></button> -->
    </div>
<!--     <div class="search">
      <input type="text" placeholder="输入订单号搜索" v-model='texts'>
      <button @click='search' class="sc"><svg><use xlink:href="#iconziyuan13"></use></svg></button>
       <button @click='clear' v-show='clearInput' class="del"><svg><use xlink:href="#iconshanchu"></use></svg></button>
    </div> -->
    <div class="otherbtm">
      <button @click='discountcenter'>领取更多优惠券>></button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'discountclass',
  data(){
    return {
      choices:1,
      texts:'',
      orderDs:false,
      clearInput:false
    }
  },
  props: {
    msg: String,
    resultNum:Number,//重置计数
    resultNum1:Number,//重置计数
    waits:Number,
    waits1:Number,
    waits2:Number,
  },
  methods:{
    discountcenter(){
      // 领券中心
      this._router({then:()=>{
        this.$router.push({path: "/discountcenter.html"})
      },exp:true,catch:()=>{
        var pms = '/discountcenter.html'
        this.$router.push({path:'/accountlogin',query:{redirect:pms}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    choice(v){
      // 切换订单
      this.orderDs = true
      this.choices = v
      this.$emit('exportTypes',v)
    },
    clear(){
      // 清空查询订单号
      this.texts=''
      this.$emit('exportsearch',this.texts)
    },
    search(){
      // 查询订单号
      this.$emit('exportsearch',this.texts)
    }
  },
  mounted(){
    this.$nextTick(()=>{
      var _ordersearchdata = JSON.parse(window.sessionStorage.getItem('ordersearchdata'))
      var _ordersearchdataRe = JSON.parse(window.sessionStorage.getItem('ordersearchdataRe'))
      if(_ordersearchdata){
        this.choices = _ordersearchdata.orderTypes
      }else if(_ordersearchdataRe){
        this.choices = _ordersearchdataRe.orderTypes
      }
    })
  },
  watch:{
    texts(n,o){
      if(n!=''||n!=null){
        this.clearInput = true
      }
      if(n==''||n==null){
        this.clearInput = false
      }
    },
    resultNum(n,o){
      this.texts=''
    },
    resultNum1(n,o){
      this.choices=1
    },
    '$store.state.orderTypeClick'(n,o){
      this.orderDs = false
    }
  }
}
</script>
<style  lang="scss" scoped>
.discountclass {
  background: #fff;height: 60px;position: relative;
  .menu {
    margin-top:25px;margin-left:20px;
    button {
      font-size: 14px;background: none;margin-right: 50px;color:#3c3c3c;position: relative;
      i {
        font-style: normal;color:#333;font-size: 14px;vertical-align: 1px;
      }
    }
    .choice {
      color:#e6555f;
      i {
        color:#e6555f;
      }
    }
  }
  .search {
    width: 296px;height: 28px;position: absolute;top:20px;right:20px;
    input {
      width: 266px;height: 28px;border: 1px solid #c1c1c1;border-radius: 5px;padding-left:30px;
      &::placeholder {
        color:#d1c6c6;
      }
    }
    .sc {
      width: 30px;height: 30px;position: absolute;top:0px;left:0px;background: none;
      svg {
        width: 15px;height: 15px;vertical-align: -3px;fill: #d1c6c6;
      }
    }
    .del {
      width: 16px;height:16px;position: absolute;top:6px;right:10px;background: #8f8e94;border-radius: 50%;
      svg {
        width: 8px;height: 8px;vertical-align: 1px;fill: #fff;
      }
    }
  }
  .otherbtm {
    position: absolute;top:22px;right:20px;
    button {
      background: none;color:#225fa2;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>

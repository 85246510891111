<template>
  <div class="discountlist">
    <div class="list">
      <div class="item"  v-for='i,n in popData' :key='n' :class='{used:i.IsUsed==2||(new Date(i.UseEndDate)<Date.now())?true:false}'  :style='{height:i.IsUsed==2||(new Date(i.UseEndDate)<Date.now())?"100px":"160px"}'>
        <!-- <div class="tip">新手专享</div> -->
        <div v-if='i.IsUsed==2' class="usedicon"><svg><use xlink:href="#iconyishiyong"></use></svg></div>
        <div v-if='(new Date(i.UseEndDate)<Date.now())' class="usedicon"><svg><use xlink:href="#iconyiguoqi"></use></svg></div>
        <div class='head'>
          <div class="lt">
            <span>&yen;</span>{{i.Amount}}
          </div>
          <div class="rt">
            <p>优惠券</p>
            <!-- <P>满{{i.meet}}使用</P> -->
            <p>{{i.ConsumptionAmount==0?'无门槛':'满'+i.ConsumptionAmount+'使用'}}</p>
          </div>
        </div>
        <div class="bottom">
          有效期至{{i.UseEndDate.split('T')[0]}}
        </div>
        <div class="icon"><svg><use xlink:href="#iconwancheng"></use></svg></div>
        <div class="usenow" v-if='i.IsUsed==1' >
          <button @click='useDiscount(i)'>立即使用</button>
        </div>
      </div>
    </div>
    <div class="page">
      <button class="back"  @click='back'>上一页 </button>
      <el-pagination
        @current-change='pages'
        :page-size='10'
        background
        layout="pager"
        :current-page.sync='pageNum'
        :total="TotalCount">
      </el-pagination>
      <button  class="next"  @click='next'>下一页</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'discountlist',
  data(){
    return {
      // discountlist:[
      //   {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      //  {time:'2020-10-08',discount:10,meet:99,choice:false},
      // ],
      // used:true,//是否使用或过期
      // 分页
      pageNum:1,
      // TotalCount:21,
    }
  },
  props: {
    popData: Array,
    TotalCount:Number,
  },
  methods:{
    useDiscount(i){
      // 使用优惠券
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/UseProductVoucher?couponcodeId='+i.Id,this.keys())
        .then(res => {
            if(res.data.Success){
              if(res.data.Data.producttypeid===''){
                if(this.$route.path.indexOf('/classlist')==-1){
                  if(res.data.Data.key){
                    var _searchQueryObj = {
                      key:res.data.Data.key,
                      orderBy:0,
                      OrderT:1,
                      parentId:'',
                      startPrice:0,
                      endPrice:0,
                      index:1
                    }
                  }else{
                     var _searchQueryObj = {
                        key:'',
                        orderBy:0,
                        OrderT:1,
                        parentId:'',
                        startPrice:0,
                        endPrice:0,
                        index:1
                      }
                  }
                  window.sessionStorage.setItem('searchQuery',JSON.stringify(_searchQueryObj))
                  window.sessionStorage.setItem('localseo',window.sessionStorage.getItem('classlistseo'))
                  this._open({path:'/classlist',query:{keys:this.searchTxt+'.html'}})
                }
              }else{
                this._router({then:()=>{
                  // this.$router.push({path:'predetail',query:{id::res.data.Data.producttypeid,idcx:'',detailType:1}})
                  this._open({path:'/predetail',query:{idcx:'',id:res.data.Data.producttypeid+'.html',}})
                }})
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
          var pms = '/index.html'
            this.$router.push({path:'/accountlogin',query:{redirect:pms}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    pages(v){
      // 分页回调
      this.$emit('exportPages',v)
    },
    back(){
      // 分页回退
      this.pageNum-=1
      if(this.pageNum<1){
        this.pageNum=1
      }
    },
    next(){
      // 分页前进
      this.pageNum+=1
      if(this.pageNum>=Math.ceil(this.TotalCount/10)){
        this.pageNum=Math.ceil(this.TotalCount/10)
      }
      // console.log(this.pageNum)
    },
    choicedis(index){
      // 选择优惠券
      if(!this.discountlist[index].choice){
        for(let i in this.discountlist){
          this.discountlist[i].choice = false
        }
        this.discountlist[index].choice = !this.discountlist[index].choice
        // this.$emit('exportCount',{method:'choice'})
        // this.setcount();
      }else{
        this.discountlist[index].choice = !this.discountlist[index].choice
        // this.$emit('exportCount',{method:'choice'})
        // this.setcount();
      }
    },
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.discountlist {
  background: #fff;padding:20px;float:right;width: 970px;padding-bottom:0px;
  .list {
    // display: flex;justify-content: flex-start;
    .item {
      width: 227.5px;height: 160px;margin-right: 20px; position: relative;overflow: hidden;border-top-left-radius: 10px;border-top-right-radius: 10px;float:left;margin-bottom:20px;
      .tip {
        position: absolute;z-index: 9;background:#f75950;font-size: 10px;color:#fff;width: 100px;transform: rotate(-45deg);text-align: center;top:15px;left:-25px;
      }

      .head {
        display: flex;justify-content: flex-start;
        position: relative;
        width: 227.5px;
        height: 70px;
        background-image:
        radial-gradient(
          circle at 9px -1px,
          transparent 8px,
          #f3d98b 6px,
          #f3d98b 0px);
        background-size:  20px 200px;
        background-position: 0 0, 200px 0;
        background-repeat-y: no-repeat;
        .lt {
          width: 100px;font-size: 46px;color:#f75950;text-align: center;line-height: 75px;margin-left:25px;
        }
        .rt {
          width: 100px;
          p {
            color:#f75950;text-align: center;
            &:nth-child(1) {
              font-weight: bold;font-size: 20px;margin-top:15px;
            }
            &:nth-child(2) {
              font-size:14px;
            }
          }
        }
      }
      .bottom {
        text-align: center;color:#818181;font-size: 14px;height: 28px;line-height: 28px;border:1px solid #ededed;border-top:0px;background: #fff;
      }
      .icon {
        width: 35px;height: 35px;position: absolute;background: #f64b43;bottom:-17px;right:-17px;transform: rotate(45deg);visibility: hidden;
        svg {
          fill: #fff;transform: rotate(-45deg);width: 12px;height: 12px;float:left;margin-top:11px;margin-left:1px;
        }
      }
      .usenow {
        width: 227.5px;height: 60px;display: flex;justify-content: center;align-items: center;
        button {
          width: 90px;height: 30px;background: none;border:1px solid #ac6f7e;color:#ac6f7e;border-radius: 5px;margin:0 auto;display: block;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      &:after {
        content: "";display: block;position: absolute;width: 196px;height: 96px;border:2px solid #f64b43;top:0px;left:0px;border-top-left-radius: 10px;border-top-right-radius: 10px;visibility: hidden;
      }
      &:nth-child(4n+4){
        margin-right: 0px;
      }
    }
    .used  {
      .tip {
        background: #7d7d7d;
      }
      .usedicon {
        width: 50px;height: 50px;position: absolute;z-index: 9;top:-10px;right:-10px;
        svg {
          width: 50px;height: 50px;fill: #7d7d7d;
        }
      }
      .head {
        background-image:
        radial-gradient(
          circle at 9px -1px,
          transparent 8px,
          #dfdfdf 6px,
          #dfdfdf 0px);
        .lt {
          color:#7d7d7d;
        }
        .rt {
          p {
            color:#7d7d7d;
          }
        }
      }
    }
    .active {
      .icon {
        visibility: visible;
      }
      &:after {
        visibility: visible;
      }
    }
  }
  .page {
    height: 80px;background: #fff;width: 1010px;float:right;display: flex;align-items: center;justify-content: center;
    .back,.next {
      height: 28px;display: inline-block;vertical-align: -4px;background: none;border:1px solid #cccccc;font-size: 12px;width: 75px;text-align: center;border-radius: 5px;color:#ccc;
      &:hover {
        background-color: #ccc!important;
        color:#797979!important;
      }
    }
    /deep/.el-pagination {
      display: inline-block;
      .number {
        color:#ccc;
        &:hover {
          color:#797979!important;
        }
      }
      .el-icon-more {
        display: none;
      }
      .active {
        background-color: #ccc!important;
        color:#797979!important;
      }
    }
  }
}
</style>
